import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import AppstoreLogo from '../../assets/images/header-appstore-logo.svg';
import GooglePlayLogo from '../../assets/images/header-gp-logo.svg';
import HeaderLogo from '../../assets/images/header-logo.svg';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      headerDesktopImage: file(relativePath: { eq: "header-appscreen-desktop.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 717, width: 447) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      headerTabletImage: file(relativePath: { eq: "header-appscreen-tablet.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 538, width: 336) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      headerMobileImage: file(relativePath: { eq: "header-appscreen-mobile.png" }) {
        childImageSharp {
          fixed(quality: 100, height: 626, width: 546) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const source = [
    data.headerDesktopImage.childImageSharp.fixed,
    {
      ...data.headerTabletImage.childImageSharp.fixed,
      media: `(max-width: 1024px) and (min-width: 768px)`,
    },
    {
      ...data.headerMobileImage.childImageSharp.fixed,
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <header className="header">
      <div className="container">
        <div className="header__inner">
          <HeaderLogo className="header__logo" />
          <div className="header__content-wrap">
            <div className="header__description">
              <h1 className="header__title">
                <span className="header__title-text">
                  Tenant <br className="mobile-br" /> meet Landlord,
                </span>{' '}
                <br className="desktop-br" />{' '}
                <span>
                  Landlord <br className="mobile-br" /> meet Tenant.
                </span>
              </h1>
              <span className="header__stores-text">DOWNLOAD OUR APP</span>
              <div className="header__stores">
                <a href="#" className="header__stores-link">
                  <AppstoreLogo className="header__stores-app-logo" />
                </a>
                <a href="#" className="header__stores-link">
                  <GooglePlayLogo className="header__stores-gp-logo" />
                </a>
              </div>
            </div>
            <div className="header__img-wrap">
              <Img fixed={source} loading="eager" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
