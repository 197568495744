import React from 'react';

import FbLogo from '../../assets/images/footer-fb-logo.svg';
import InstaLogo from '../../assets/images/footer-insta-logo.svg';

const Footer = ({ hideSocial = false }) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          {hideSocial && (
            <>
              <h2 className="footer__title">Follow on social</h2>
              <div className="footer__social">
                <a
                  href="https://www.instagram.com/pad.app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__social-link"
                >
                  <InstaLogo className="footer__social-logo" />
                  <span className="footer__social-text">Instagram</span>
                </a>
                <a
                  href="https://www.facebook.com/padapp"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer__social-link"
                >
                  <FbLogo className="footer__social-logo" />
                  <span className="footer__social-text">Facebook</span>
                </a>
              </div>
            </>
          )}
          <div className="footer__company">
            <p className="footer__pad">© {new Date().getFullYear()} - Pad ®</p>
            <p className="footer__info">
              Pad Innovation Limited, 2-4 Packhorse Road, Gerrards Cross, Buckinghamshire,
              England, SL9 7QE
            </p>
            <p className="footer__number">Company number: 09785166</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
